import { addBreadcrumb } from '@thriveglobal/thrive-web-core'
import { auth, SigninResult } from '@thriveglobal/thrive-web-auth-core'
import { app } from '@microsoft/teams-js'
import { getParamRelay } from './getParamRelay'

export function signIntoThrive(
    context: app.Context,
    token: string
): Promise<SigninResult> {
    const deepLinkPath = context.page?.subPageId
    if (deepLinkPath) {
        addBreadcrumb({
            category: 'auth',
            message: 'captured deep link path',
            data: deepLinkPath
        })
    }

    const relayPath = deepLinkPath ? deepLinkPath : getParamRelay()
    auth.configureAzure({
        azureToken: token,
        azureRelayPath: `/${relayPath}`
    })
    return auth.signIn()
}
