import { app, authentication } from '@microsoft/teams-js'
import { captureException, captureMessage } from '@thriveglobal/thrive-web-core'
import { ErrorScreenVariant } from '@thriveglobal/thrive-web-leafkit'
import {
    deleteRetryAuthState,
    increaseRetryAuthCount,
    loadRetryAuthState
} from './manageRetryStatesLocally'
import { retry } from './promiseRetry'
import { requestTokenWithExtraPermissions } from './requestTokenWithExtraPermissions'
import { signIntoThrive } from './signIntoThrive'

export async function addInfoToDatadog(context: app.Context) {
    try {
        const tenantId = context?.user?.tenant?.id
        window?.DD_LOGS?.setGlobalContextProperty('tenantId', tenantId)
        window?.DD_RUM?.setGlobalContextProperty('tenantId', tenantId)
    } catch (error) {
        captureMessage('Failed to add azure token info to datadog')
    }
}

export async function getAuthentication(
    setError: Function,
    setReload: Function,
    tempUserId: string
): Promise<string> {
    return retry<string, string>(
        authentication.getAuthToken,
        (token) => {
            deleteRetryAuthState()

            return Promise.resolve(token)
        },
        (error, reload, retryCount) => {
            const cancelledByUser = 'CancelledByUser'
            const canReload =
                reload &&
                error !== cancelledByUser &&
                error?.message !== cancelledByUser
            captureException(error, {
                message: `tokenExchange of Azure token for Thrive token failed, retry ${retryCount}`,
                tempUserId
            })

            setReload(canReload)

            if (!canReload) {
                setError({
                    variant: ErrorScreenVariant.AuthError,
                    reason: error.message
                })
                app?.notifyFailure({
                    reason: app.FailedReason.AuthFailed,
                    message: 'we were unable to log you in'
                })
            }

            return canReload
        },
        loadRetryAuthState,
        increaseRetryAuthCount
    )
}

export async function tokenExchange(
    setIsLoggedIn: (boolean) => void,
    setError: Function,
    setReload: Function,
    setShowPermissionRequestScreen: Function,
    context: app.Context,
    tempUserId
) {
    await getAuthentication(setError, setReload, tempUserId).then(
        async (azureToken) => {
            try {
                if (!azureToken) {
                    throw new Error(
                        'unable to get azure token from teams client'
                    )
                }

                const result = await signIntoThrive(context, azureToken)
                if (
                    result.resultType === 'failed' &&
                    result.reason === 'missingPermissions'
                ) {
                    setShowPermissionRequestScreen(true)
                    return
                } else if (
                    result.resultType === 'failed' &&
                    result.reason === 'idpNotConfigured'
                ) {
                    setError({
                        variant: 'NotConfigured',
                        reason: 'company not configured'
                    })
                    return
                } else if (result.resultType !== 'success') {
                    throw new Error('unable to get token from Thrive')
                }
                setIsLoggedIn(true)
                app.notifyAppLoaded()
                app.notifySuccess()
            } catch (error) {
                captureException(error, {
                    message:
                        'tokenExchange of Azure token for Thrive token failed',
                    tempUserId
                })
                setError({
                    variant: ErrorScreenVariant.AuthError,
                    reason: error.message
                })
                app?.notifyFailure({
                    reason: app.FailedReason.AuthFailed,
                    message: 'we were unable to log you in'
                })
            }
        }
    )
}

export async function tokenExchangeWithTokenWithExtraPermissions(
    setIsLoggedIn: (boolean) => void,
    setError: Function,
    context: app.Context,
    tempUserId: string
) {
    try {
        const consentedToken = await requestTokenWithExtraPermissions(context)

        if (!consentedToken) {
            throw new Error(
                'unable to get token with extra permissions from teams client'
            )
        }
        const result = await signIntoThrive(context, consentedToken)
        if (result.resultType !== 'success') {
            throw new Error(
                `login was not successful: ${result.resultType}, ${result.reason}`
            )
        }
        setError({
            variant: null,
            reason: ''
        })
        setIsLoggedIn(true)
        app.notifyAppLoaded()
        app.notifySuccess()
    } catch (error) {
        captureException(error, {
            message: 'tokenExchange of Azure token for Thrive token failed',
            tempUserId
        })
        setError({
            variant: ErrorScreenVariant.AuthError,
            reason: error.message
        })
        app?.notifyFailure({
            reason: app.FailedReason.AuthFailed,
            message: 'we were unable to log you in'
        })
    }
}
